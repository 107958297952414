// src/components/LoginSignup.tsx

import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { motion } from "framer-motion";
import LogoCarousel from "./ExploreCarousel";
import Globe from "react-globe.gl";
import myGif1 from "../video/my_gif.gif";
import myGif2 from "../video/gif.gif";

interface AuthFormProps {
  isLogin: boolean;
  handleSubmit: (e: FormEvent) => Promise<void>;
  loading: boolean;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  showPassword: boolean;
  togglePasswordVisibility: () => void;
  toggleAuthMode: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  isLogin,
  handleSubmit,
  loading,
  email,
  setEmail,
  password,
  setPassword,
  showPassword,
  togglePasswordVisibility,
  toggleAuthMode,
}) => (
  <motion.div
    className="bg-white w-full max-w-md p-8 md:p-10 rounded-lg shadow-lg transition-transform transform hover:scale-105"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="mb-6 text-center">
      <img src={myGif1} alt="Loading GIF" className="w-48 h-auto mx-auto md:w-72" />
    </div>
    <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
      {isLogin ? "Welcome Back" : "Join Us Today!"}
    </h2>
    <form onSubmit={handleSubmit} className="space-y-5">
      <motion.input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full px-4 py-3 bg-gray-100 rounded-lg border focus:ring-2 focus:ring-blue-500"
        required
        whileFocus={{ scale: 1.02 }}
      />
      <div className="relative">
        <motion.input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-3 bg-gray-100 rounded-lg border focus:ring-2 focus:ring-blue-500"
          required
          whileFocus={{ scale: 1.02 }}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-4 text-gray-500"
          style={{ backgroundColor: "transparent" }}
        >
          {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
        </button>
      </div>

      {!isLogin && (
        <div className="flex items-center text-sm text-gray-600">
          <input
            type="checkbox"
            required
            className="form-checkbox h-4 w-4 text-blue-600"
          />
          <label className="ml-2">
            I agree to the{" "}
            <a href="#" className="text-blue-600 underline">
              Reker Terms & Conditions
            </a>
          </label>
        </div>
      )}

      <motion.button
        type="submit"
        disabled={loading}
        className={`w-full py-3 font-semibold bg-blue-600 text-white rounded-lg flex items-center justify-center transition duration-300 ${
          loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
        }`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {loading ? <FiLoader className="animate-spin" /> : "Continue"}
      </motion.button>
    </form>

    <div className="mt-4 text-center">
      {isLogin ? (
        <p className="text-gray-600">
          Don’t have an account?{" "}
          <span className="text-gray-500 text-xs">
            Reach us at{" "}
            <a
              href="mailto:query@reker.world"
              className="text-blue-600 underline"
            >
              brands@reker.world
            </a>
          </span>
        </p>
      ) : (
        <p className="text-gray-600">
          Already have an account?{" "}
          <button onClick={toggleAuthMode} className="text-blue-600 underline">
            Log In
          </button>
        </p>
      )}
    </div>
  </motion.div>
);

export const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Login successful");
      navigate("/explore");
    } catch (error: any) {
      console.error(error);
      toast.error(
        error.code === "auth/user-not-found"
          ? "No account found for this email."
          : "Login failed. Check your credentials."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gray-50">
      {/* Left Section */}
      <div className="flex flex-col md:w-1/2 bg-white items-center justify-center p-8 md:p-10 space-y-6 text-center mt-14">
        <h1 className="text-2xl md:text-3xl font-semibold text-black">
          AR Gamified World
        </h1>
        <div className="mb-4">
          <img src={myGif2} alt="Loading GIF" className="w-40 md:w-60 h-auto mx-auto" />
        </div>
        <p className="text-gray-600 text-md md:text-lg font-semibold">
          Boost Engagement with AR-Powered Rewards
        </p>
        <h1 className="text-gray-700 font-semibold mt-4 text-sm md:text-base">
        Increase your retention and ife time value</h1>
        <h1 className="text-gray-700 font-semibold mt-4 text-sm md:text-base">
        Transform the way customers interact with your brand using our AR platform, offering immersive, tailored rewards for continuous loyalty.</h1>
        <h1 className="text-gray-700 font-semibold mt-4 text-sm md:text-base">Monitor your success with comprehensive loyalty graphs and a powerful analytics dashboard.</h1>
        <div className="w-full mt-6">
          <LogoCarousel />
        </div>
        <div className="relative mt-8">
          <p className="text-sm text-gray-500 mb-2">Drag to rotate</p>
          <div
            title="Drag to rotate the globe"
            className="cursor-grab transition-transform transform hover:scale-105"
            style={{ width: "250px", height: "250px" }}
          >
            <Globe
              globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
              backgroundColor="rgba(0, 0, 0, 0)"
              height={250}
              width={250}
              animateIn={true}
            />
          </div>
        </div>
      </div>

      {/* Right Section (Login Form) */}
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 p-6">
        <ToastContainer />
        <AuthForm
          isLogin={true}
          handleSubmit={handleLogin}
          loading={loading}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          showPassword={showPassword}
          togglePasswordVisibility={togglePasswordVisibility}
          toggleAuthMode={() => navigate("/signup")}
        />
      </div>
    </div>
  );
};
