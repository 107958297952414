import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

// Import local images
import shoppingImg from "../Image/shoping.png";
import foodImg from "../Image/food.png";
import travel from "../Image/travel.png";
import groceryImg from "../Image/supermart.png";
import commute from "../Image/commute.png";

// Array of category images
const categories = [
  { image: shoppingImg },
  { image: foodImg },
  { image: travel },
  { image: groceryImg },
  { image: commute },
];

const LogoCarousel: React.FC = () => {
  return (
    <div className="flex justify-center my-6">
      <div className="w-full max-w-md">
        <Swiper
          modules={[Navigation, Autoplay]}
          navigation={{
            nextEl: ".c-carousel__button--next",
            prevEl: ".c-carousel__button--prev",
          }}
          loop={true}
          spaceBetween={80}
          slidesPerView={3}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          className="w-full"
        >
          {categories.map((category, index) => (
            <SwiperSlide key={index} className="flex items-center justify-center">
              <img src={category.image} alt={`Slide ${index}`} className="h-16 w-auto" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default LogoCarousel;
