import React, { useState } from "react";
import {
  FaFileAlt,
  FaChartLine,
  FaUsers,
  FaSignOutAlt,
} from "react-icons/fa";
import { IconType } from "react-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";

interface SidebarItemProps {
  icon: IconType;
  label: string;
  active?: boolean;
  onClick: () => void;
}

interface RadioGroupProps {
  label: string;
  value: DataOptionKeys;
  selected: DataOptionKeys;
  onClick: (value: DataOptionKeys) => void;
}

type DataOptionKeys = "Today" | "Yesterday" | "Last 7 days";

const randomData = [
  { name: "Jan", value: Math.floor(Math.random() * 5000) },
  { name: "Feb", value: Math.floor(Math.random() * 5000) },
  { name: "Mar", value: Math.floor(Math.random() * 5000) },
  { name: "Apr", value: Math.floor(Math.random() * 5000) },
  { name: "May", value: Math.floor(Math.random() * 5000) },
  { name: "Jun", value: Math.floor(Math.random() * 5000) },
  { name: "Jul", value: Math.floor(Math.random() * 5000) },
  { name: "Aug", value: Math.floor(Math.random() * 5000) },
];

const Explore = () => {
  const [activeSidebarItem, setActiveSidebarItem] = useState("Reports");
  const [selectedRadio, setSelectedRadio] = useState<DataOptionKeys>("Today");
  const navigate = useNavigate();

  const dataOptions: Record<DataOptionKeys, { name: string; value: number }[]> = {
    Today: randomData.map((d) => ({ ...d, value: Math.floor(Math.random() * 1000) })),
    Yesterday: randomData.map((d) => ({ ...d, value: Math.floor(Math.random() * 2000) })),
    "Last 7 days": randomData.map((d) => ({ ...d, value: Math.floor(Math.random() * 7000) })),
  };

  const handleSidebarClick = (label: string) => {
    setActiveSidebarItem(label);
    console.log(`${label} clicked`);
  };

  const handleRadioClick = (value: DataOptionKeys) => {
    setSelectedRadio(value);
    console.log(`${value} selected`);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const metricData: Record<DataOptionKeys, { label: string; value: string; change: string }[]> = {
    Today: [
      { label: "Total Impressions", value: "5,123,456", change: "+12%" },
      { label: "Total Clicks", value: "23,567", change: "-3%" },
      { label: "Total Conversions", value: "1,234", change: "+5%" },
      { label: "Total GMV", value: "45,678", change: "+9%" },
      { label: "Total AR Interactions", value: "12,345", change: "+8%" },
      { label: "Total AR Experiences", value: "9,876", change: "+15%" },
    ],
    Yesterday: [
      { label: "Total Impressions", value: "5,000,000", change: "+10%" },
      { label: "Total Clicks", value: "24,000", change: "-2%" },
      { label: "Total Conversions", value: "1,300", change: "+4%" },
      { label: "Total GMV", value: "44,000", change: "+7%" },
      { label: "Total AR Interactions", value: "11,500", change: "+6%" },
      { label: "Total AR Experiences", value: "9,500", change: "+14%" },
    ],
    "Last 7 days": [
      { label: "Total Impressions", value: "45,000,000", change: "+20%" },
      { label: "Total Clicks", value: "210,000", change: "-5%" },
      { label: "Total Conversions", value: "7,000", change: "+6%" },
      { label: "Total GMV", value: "400,000", change: "+10%" },
      { label: "Total AR Interactions", value: "100,000", change: "+12%" },
      { label: "Total AR Experiences", value: "85,000", change: "+18%" },
    ],
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-900">
      {/* Sidebar */}
      <aside className="w-full md:w-64 bg-gray-800 p-4 md:p-6 shadow-xl rounded-l-lg transition-transform duration-300">
        <div className="flex flex-col gap-4">
          <SidebarItem
            icon={FaFileAlt}
            label="Reports"
            active={activeSidebarItem === "Reports"}
            onClick={() => handleSidebarClick("Reports")}
          />
          {/* <SidebarItem
            icon={FaUsers}
            label="People"
            active={activeSidebarItem === "People"}
            onClick={() => handleSidebarClick("People")}
          /> */}
          <SidebarItem icon={FaSignOutAlt} label="Logout" onClick={handleLogout} />
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-4 md:p-10 bg-gray-800 shadow-xl rounded-lg mx-4 md:mx-8 mt-4 md:mt-8 mb-8">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <h1 className="text-3xl md:text-4xl font-bold text-white mb-4 md:mb-0">Performance Overview</h1>

          <div className="flex space-x-2 bg-gray-700 border rounded-full shadow-md p-2 overflow-x-auto">
            {["Today", "Yesterday", "Last 7 days"].map((option) => (
              <RadioGroup
                key={option}
                label={option}
                value={option as DataOptionKeys}
                selected={selectedRadio}
                onClick={handleRadioClick}
              />
            ))}
          </div>
        </div>

        {/* Performance metrics section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {metricData[selectedRadio].map((item, index) => (
            <div
              key={index}
              className="p-4 bg-gray-900 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl border border-gray-700"
            >
              <p className="text-gray-400">{item.label}</p>
              <p className="text-2xl md:text-3xl font-semibold text-white">{item.value}</p>
              <p
                className={`text-sm ${
                  item.change.startsWith("+") ? "text-green-400" : "text-red-400"
                }`}
              >
                {item.change} from last week
              </p>
            </div>
          ))}
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Total Impressions Chart */}
          <div className="p-6 bg-gray-900 rounded-lg shadow-xl border border-gray-700 transition-shadow hover:shadow-2xl">
            <h2 className="text-lg font-semibold text-gray-200 mb-4">Total Impressions</h2>
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={dataOptions[selectedRadio]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#ffffff" />
                <YAxis stroke="#ffffff" />
                <Tooltip contentStyle={{ backgroundColor: "#2D3748", color: "#fff" }} />
                <Line type="monotone" dataKey="value" stroke="#4FD1C5" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Total Clicks Chart */}
          <div className="p-6 bg-gray-900 rounded-lg shadow-xl border border-gray-700 transition-shadow hover:shadow-2xl">
            <h2 className="text-lg font-semibold text-gray-200 mb-4">Total Clicks</h2>
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={dataOptions[selectedRadio]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#ffffff" />
                <YAxis stroke="#ffffff" />
                <Tooltip contentStyle={{ backgroundColor: "#2D3748", color: "#fff" }} />
                <Line type="monotone" dataKey="value" stroke="#68D391" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </main>
    </div>
  );
};

const SidebarItem: React.FC<SidebarItemProps> = ({ icon: Icon, label, active, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center p-3 rounded-lg transition-colors duration-300 ${
      active ? "bg-gray-700 text-white" : "bg-gray-800 text-gray-400"
    } hover:bg-gray-700 hover:text-white`}
  >
    <Icon className="mr-2" />
    {label}
  </button>
);

const RadioGroup: React.FC<RadioGroupProps> = ({ label, selected, value, onClick }) => (
  <button
    onClick={() => onClick(value)}
    className={`px-4 py-2 rounded-full text-sm font-semibold transition-all duration-300 ${
      selected === value ? "bg-blue-600 text-white" : "bg-gray-600 text-gray-200"
    }`}
  >
    {label}
  </button>
);

export default Explore;
