// ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Firebase auth import

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Show loading spinner or placeholder until authentication status is confirmed
  if (loading) {
    return <div>Loading...</div>; // Optional: Replace with a loading spinner component
  }

  // Redirect to login page if user is not authenticated
  if (!user) {
    return <Navigate to="/login" />;
  }

  // If the user is authenticated, render the protected component
  return children;
};

export default ProtectedRoute;
