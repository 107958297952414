import React, { useState, useEffect } from "react";
import Track from "../Image/track.png";
import Earn from "../Image/earn.png";
import Earth from "../Image/earth.png";
import Android from "../Image/Android.png";

const ImageSection: React.FC = () => {
  const myVideo = require("../video/Second_frame.mp4");

  return (
    <section className="relative w-full h-screen overflow-hidden">
      {/* Background Video */}
      <video
        className="absolute inset-0 w-full h-full object-cover"
        src={myVideo}
        autoPlay
        muted
        playsInline
        loop
      />
      {/* Main Heading Overlay */}
      <div className="relative flex flex-col items-center h-full text-center text-white px-4 pt-44">
        <h2 className="text-7xl md:text-4xl font-bold mt-4 pb-10">
          WELCOME TO REKER
        </h2>
        <h1 className="text-4xl md:text-5xl font-bold">
          World's First Climate Combative Lifestyle App
        </h1>
      </div>
    </section>
  );
};

const Home: React.FC = () => {
  const Second_frame = require("../video/my_video1.mp4");
  const [showOverlay, setShowOverlay] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowOverlay(true);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleJoinClick = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <main className="flex-grow">
        {/* Hero Section */}
        <ImageSection />
        <section className="bg-gray-200 py-10">
          <div className=" mx-auto text-center">
            <h1 className="text-center text-xl">
            Earn carbon credits and coins by using eco-friendly modes of
              transport like Metro, EV Rides, Walking or Bus <br/>to save the
              environment while on the go.
            </h1>
          </div>
        </section>
        {/* Features Section */}
        <section className="py-10 bg-white text-gray-800">
          <div className="container mx-auto text-center">
            <div className="grid gap-8 md:grid-cols-3">
              {[
                {
                  img: Track,
                  title: "Track Your Commute",
                  desc: "Automatically track your eco-friendly commutes with ease.",
                },
                {
                  img: Earn,
                  title: "Earn Rewards",
                  desc: "Get credits and rewards for making sustainable choices.",
                },
                {
                  img: Earth,
                  title: "Support the Planet",
                  desc: "Help reduce carbon emissions and make a difference.",
                  bgColor: "black",
                },
              ].map(({ img, title, desc, bgColor }, idx) => (
                <div
                  key={idx}
                  className="bg-gray-50 rounded-lg shadow-md p-6 transform transition-transform duration-300 hover:scale-105"
                >
                  <div className={`flex justify-center mb-4 bg-${bgColor}-100 p-4 rounded-full`}>
                    <img src={img} alt={title} className="h-10 w-10" />
                  </div>
                  <h3 className={`text-xl font-semibold text-${bgColor}-600`}>
                    {title}
                  </h3>
                  <p className="text-gray-600 mt-2">{desc}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* App Download Section */}
        <section className="bg-gray-200 py-10">
          <div className="container mx-auto text-center">
            <img src={Android} alt="Download on Android" className="mx-auto h-16" />
          </div>
        </section>

        {/* Additional Video Section */}
        <section className="relative w-full mt-10">
          <video
            className="w-full h-auto object-cover"
            src={Second_frame}
            autoPlay
            muted
            playsInline
            loop
          />
          <div
            className={`absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 p-6 transition-opacity duration-500 ${
              showOverlay ? "opacity-100" : "opacity-0"
            }`}
          >
            <p className="text-white text-4xl mb-6 max-w-full pb-10">
              Reker World is India’s first AR-Gamified rewarding platform
            </p>
            {/* <p className="text-2xl font-bold text-white mb-6 text-center">
              Earn carbon credits and coins by using eco-friendly modes of
              transport like Metro, EV Rides, Walking or Bus to save the
              environment while on the go.
            </p> */}
            <button
              onClick={handleJoinClick}
              className="px-8 py-3 rounded-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-bold shadow-lg hover:shadow-xl transform transition-transform duration-300 hover:scale-105"
            >
              Join the Waitlist
            </button>
          </div>

          {/* Toast Notification */}
          {showToast && (
            <div className="fixed bottom-5 right-5 p-4 bg-green-500 text-white rounded-lg shadow-lg transition-opacity duration-300 z-50">
              Thank you! You have joined the Waitlist.
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Home;
