import React, { useState } from "react";
import { db } from "../firebaseConfig"; // Adjust the import path as necessary
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), formData);
      toast.success("Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      toast.error("Error submitting form.");
      console.error("Error submitting form: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center text-black">
      <ToastContainer />
      <div className="container mx-auto px-4 lg:px-8 py-16 mt-12 mb-12"> {/* Added margin top and bottom */}
        <div className="grid lg:grid-cols-2 gap-10">
          
          {/* Left Section with Info */}
          <div className="bg-white border border-gray-200 p-8 rounded-xl shadow-md space-y-8">
            <h2 className="text-4xl font-extrabold text-center text-black">
              Let’s Get in Touch!
            </h2>
            <p className="text-center text-lg text-gray-600">
              Whether you have a question, want to collaborate, or just want to
              say hi – we’re here! Share your ideas, and let’s work together
              towards a better, greener future.
            </p>

            {/* Map Section */}
            <div className="space-y-4">
              <h3 className="text-2xl font-semibold text-black">Our Location</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5379110531184!2d77.6333333!3d12.9141417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14100972bc39%3A0x1a24a5db7f69c6ed!2s1502%2C%2019th%20Main%20Rd%2C%201st%20Sector%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102%2C%20India!5e0!3m2!1sen!2sus!4v1695115202567!5m2!1sen!2sus"
                width="100%"
                height="250"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden={false}
                tabIndex={0}
                title="Google Map of HSR Layout, Bengaluru"
                className="rounded-lg"
              ></iframe>
            </div>
          </div>
          
          {/* Right Section with Contact Form */}
          <div className="bg-white border border-gray-200 p-8 rounded-xl shadow-md">
            <h3 className="text-3xl font-bold text-center mb-6 text-black">
              Drop Us a Message
            </h3>
            <form onSubmit={handleSubmit} className="space-y-6">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-4 bg-gray-50 border border-gray-300 rounded-lg text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Name"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-4 bg-gray-50 border border-gray-300 rounded-lg text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Email"
                required
              />
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full p-4 bg-gray-50 border border-gray-300 rounded-lg text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Subject"
                required
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-4 bg-gray-50 border border-gray-300 rounded-lg text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Message"
                rows={4}
                required
              ></textarea>
              <button
                type="submit"
                className="w-full py-4 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded-lg transition-all duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
