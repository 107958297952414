import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faRedditAlien } from "@fortawesome/free-brands-svg-icons"; // Import the X (formerly Twitter) icon

const Footer: React.FC = () => {
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer id="contact" className="text-white py-8 footer-section bg-gray-900">
      <div className="container mx-auto text-center md:text-left px-4">
        {/* Footer Links & Social Media */}
        <div className="flex flex-col md:flex-row md:justify-between items-center md:items-start mb-6 space-y-6 md:space-y-0">
          {/* Links Section */}
          <div className="mb-4 md:mb-0 flex flex-col space-y-2 text-sm md:text-base">
            <Link
              to="/terms-and-conditions"
              className="hover:underline"
              onClick={scrollToTop}
            >
              Terms and Conditions
            </Link>
            {/* <Link to="/blog" className="hover:underline" onClick={scrollToTop}>
              Blog
            </Link> */}
          </div>

          {/* Social Media Section */}
          <div className="text-center md:text-left">
            {/* <p className="mb-2 font-semibold text-lg md:text-xl">
              Our Social Networks
            </p>
            <p className="text-sm md:text-base mb-4 text-gray-300">
              Follow us to get more updates
            </p> */}
            {/* Social Media Icons */}
            <div className="flex space-x-6 justify-center md:justify-start">
              <a
                href="https://www.instagram.com/reker.world"
                target="_blank"
                rel="noopener noreferrer"
                className="transition duration-300 transform hover:scale-125 hover:text-gray-400"
                aria-label="Instagram"
                title="Instagram"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                  <path d="M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
                  <line x1="17.5" y1="6.5" x2="17.5" y2="6.5" />
                </svg>
              </a>

              <a
                href="https://www.youtube.com/@rekerworld"
                target="_blank"
                rel="noopener noreferrer"
                className="transition duration-300 transform hover:scale-125 hover:text-gray-400"
                aria-label="YouTube"
                title="YouTube"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#FFFFFF"
                >
                  <path d="M23.498 6.186a3.006 3.006 0 0 0-2.107-2.1C19.154 4 12 4 12 4s-7.154 0-9.391.086A3.006 3.006 0 0 0 .502 6.186C.189 7.348 0 8.495 0 12s.189 4.652.502 5.814a3.006 3.006 0 0 0 2.107 2.1C4.846 20 12 20 12 20s7.154 0 9.391-.086a3.006 3.006 0 0 0 2.107-2.1C23.811 16.652 24 15.505 24 12s-.189-4.652-.502-5.814zM9 15V9l6 3-6 3z" />
                </svg>
              </a>

              <a
                href="https://www.x.com/rekerworld"
                target="_blank"
                rel="noopener noreferrer"
                className="transition duration-300 transform hover:scale-125 hover:text-gray-400"
                aria-label="X"
                title="X"
              >
                <FontAwesomeIcon icon={faXTwitter} size="lg" />
              </a>

              <a
                href="https://www.reddit.com/user/rekerworld"
                target="_blank"
                rel="noopener noreferrer"
                className="transition duration-300 transform hover:scale-125 hover:text-gray-400"
                aria-label="Reddit"
                title="Reddit"
              >
                <FontAwesomeIcon icon={faRedditAlien} size="lg" />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Text */}
        <div className="border-t border-gray-700 pt-4">
          <p className="text-sm md:text-base text-center text-gray-400">
            &copy; 2024 EvolveVol Tech Pvt. Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
