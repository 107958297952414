import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditions: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col py-10 px-6 md:px-12">
      {/* Outer container with 15% border/margin */}
      <div className="mx-[15%] mt-10">
        <div className="max-w-5xl mx-auto text-center">
          <h1 className="text-5xl text-white font-bold mb-6">
            Terms and Conditions
          </h1>
          <p className="text-white text-sm">
            Effective Date: September 23, 2024
          </p>
        </div>

        <section className="bg-white shadow-md rounded-lg p-6">
          {" "}
          {/* Fixed here */}
          <h2 className="text-sm font-semibold mt-4 mb-2">
            1. Acceptance of Terms
          </h2>
          <p className="text-xs">
            By downloading, installing, and using the REKER mobile application
            ("App"), you are agreeing to comply with and be bound by these Terms
            and Conditions. These terms apply to all users of the App, including
            those accessing it on mobile devices and those interacting with the
            services provided therein. The use of the App is conditioned upon
            your acceptance of these terms, and if you do not agree, you should
            cease usage immediately. The use of any services we offer through
            the App will also be subject to these Terms, and any specific terms
            related to particular services will be considered part of this
            agreement.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">2. Eligibility</h2>
          <p className="text-xs">
            In order to use the App, you must be at least 18 years of age or the
            age of majority in your jurisdiction. By accessing or using the App,
            you represent that you meet the eligibility criteria and have the
            legal capacity to enter into binding agreements under applicable
            laws. If you are below this age, you may only use the App under the
            supervision of a parent or legal guardian who agrees to be bound by
            these Terms. Failure to meet these eligibility criteria may result
            in the immediate termination of your account.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">3. License to Use</h2>
          <p className="text-xs">
            REKER grants you a limited, non-exclusive, non-transferable,
            revocable license to download, install, and use the App for personal
            and non-commercial purposes, strictly in accordance with these Terms
            and any applicable laws. You are prohibited from using the App for
            commercial purposes, and unauthorized copying, modification,
            distribution, sale, or lease of any part of the App is strictly
            forbidden. You agree that you will not reverse engineer, decompile,
            or attempt to extract the source code of the software, except where
            permitted by law.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">4. User Account</h2>
          <p className="text-xs">
            In order to access certain features of the App, you may be required
            to create a user account by providing accurate, current, and
            complete information. It is your responsibility to ensure that the
            information you provide remains accurate and up-to-date. You are
            solely responsible for maintaining the confidentiality of your login
            credentials and for any activity that occurs under your account. If
            you suspect any unauthorized access or activity in your account, you
            must notify us immediately. We will not be responsible for any loss
            or damage arising from your failure to safeguard your account.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">5. Game Features</h2>
          <p className="text-xs">
            The App may offer various interactive features such as games,
            competitions, and rewards programs. These features are intended for
            entertainment purposes only and may include in-app purchases that
            enhance the gaming experience. Any rewards earned through
            participation are non-transferable and may be subject to additional
            terms. In-app purchases may include virtual goods or currency, which
            do not have real-world value and cannot be exchanged for money or
            other tangible items. All in-app transactions are final and
            non-refundable, except as required by applicable law.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">6. Payments</h2>
          <p className="text-xs">
            For certain features or services in the App, you may be required to
            make payments. By providing payment information, you agree that all
            information is accurate and you authorize us or our third-party
            payment processors to charge you for the applicable fees. These
            transactions will be processed by a third-party payment processor,
            and we do not store sensitive payment information. Any issues or
            disputes related to payments should be addressed directly with the
            payment processor. You are responsible for all charges associated
            with your use of paid services, including any taxes or fees.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">7. Camera Access</h2>
          <p className="text-xs">
            Certain features of the App may require access to your device's
            camera, such as for capturing images for profile customization or
            participating in augmented reality experiences. You can control
            whether the App has access to your camera by adjusting your device's
            settings. The App will only access the camera with your consent, and
            any media captured will only be stored or used with your permission.
            If you deny access to the camera, some features of the App may not
            function as intended.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            8. Location Access
          </h2>
          <p className="text-xs">
            The App may request access to your device’s precise or approximate
            location data to offer personalized services or features. You can
            manage the location permissions through your device’s privacy
            settings. If you choose to disable location services, certain
            features of the App may be restricted. We are committed to using
            your location data in compliance with our Privacy Policy, and it
            will not be shared with third parties without your explicit consent.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            9. Data Collection and Privacy
          </h2>
          <p className="text-xs">
            Your privacy is of paramount importance to us. We collect and use
            your data in accordance with our Privacy Policy, which is available
            for review on our website or within the App. By using the App, you
            consent to the collection, processing, and storage of your personal
            data, as outlined in the Privacy Policy. We employ industry-standard
            security measures to protect your data, but no method of
            transmission over the internet or electronic storage is completely
            secure. You agree that we cannot guarantee absolute security.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            10. Prohibited Conduct
          </h2>
          <p className="text-xs">
            You agree not to use the App for any unlawful, harmful, or
            fraudulent purposes, including but not limited to violating the
            rights of others, transmitting malware or malicious software,
            engaging in activities that could compromise the security or
            integrity of the App, or infringing on the intellectual property
            rights of REKER or third parties. Any such conduct may result in the
            immediate suspension or termination of your account and may lead to
            legal action.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            11. Intellectual Property
          </h2>
          <p className="text-xs">
            All content within the App, including but not limited to text,
            graphics, logos, images, software, and other materials, is the
            property of REKER or its licensors. These materials are protected by
            intellectual property laws, including copyrights, trademarks, and
            trade secrets. Unauthorized use of any content in the App is
            strictly prohibited, and you agree not to copy, modify, distribute,
            or create derivative works based on the App without our prior
            written consent.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            12. Limitation of Liability
          </h2>
          <p className="text-xs">
            To the maximum extent permitted by applicable law, REKER shall not
            be liable for any indirect, incidental, special, or consequential
            damages, including but not limited to loss of profits, data, or
            business opportunities, arising from your use or inability to use
            the App or any services provided therein. Your use of the App is at
            your sole risk, and we do not guarantee that the App will be
            uninterrupted, secure, or free from errors.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            13. Changes to Terms
          </h2>
          <p className="text-xs">
            We reserve the right to modify these Terms at any time. Changes will
            be effective immediately upon posting the revised Terms in the App.
            You are encouraged to review the Terms periodically for any updates.
            Your continued use of the App following any changes constitutes your
            acceptance of the new Terms. If you do not agree to the revised
            Terms, you must stop using the App immediately.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">14. Governing Law</h2>
          <p className="text-xs">
            These Terms shall be governed by and construed in accordance with
            the laws of the jurisdiction in which REKER operates, without regard
            to its conflict of law principles. Any legal action arising from
            these Terms or your use of the App shall be brought exclusively in
            the courts located in that jurisdiction.
          </p>
          <h2 className="text-sm font-semibold mt-4 mb-2">
            15. Contact Information
          </h2>
          <p className="text-xs">
            If you have any questions or concerns regarding these Terms or the
            App, please contact us at:
          </p>
          <p className="text-xs">Email: support@reker.world</p>
          <div className="flex justify-center mt-6">
            <Link
              to="/privacy-policy"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicked
            >
              View Privacy Policy
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
