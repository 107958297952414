// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Added this to get the Auth instance

const firebaseConfig = {
  apiKey: "AIzaSyDRRFOTRULuz1mDZsGen5I5TSWBV_3ZRZI",
  authDomain: "reker-world-website.firebaseapp.com",
  projectId: "reker-world-website",
  storageBucket: "reker-world-website.appspot.com",
  messagingSenderId: "565942971875",
  appId: "1:565942971875:web:16e9b6343639a2cfce04d6",
  measurementId: "G-L495SN9004",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Get the Firebase Auth instance

export { db, auth };
